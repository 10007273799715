import React from "react"
import { Routes, Route } from "react-router-dom"
import { Row, Col, Grid, Divider } from 'antd'
import SubscriptionsPending from '../../Subscriptions/Pending'
import Homepage from '../../Homepage'
import Help from '../../Help'
import Legals from '../../Pages/Legals'
import Menu from './Menu'

const { useBreakpoint } = Grid

export default function LoggedIn() {
  const screens = useBreakpoint()

  return (
    <Row gutter={[0, 64]} >
      <Col
        xs={0}
        lg={8}
        className="ven-menu"
        style={{ paddingTop: screens.lg ? 32 : 0 }}
      >
        <Menu />
      </Col>

      <Col xs={24} lg={16} style={{ maxWidth: 1200, paddingTop: screens.lg ? 0 : 0, paddingLeft: screens.lg ? 32 : 0 }}>
        <Routes>
          <Route path="/" element={<div className="ven-content-text"><Homepage /></div>} />
          <Route path="/help" element={<div className="ven-content-text"><Help /></div>} />
          <Route path="/legals" element={<div className="ven-content-text"><Legals /></div>} />
          <Route path="/success/:subscriptionId" element={<div className="ven-content-text"><SubscriptionsPending /></div>} />
        </Routes>
      </Col>

      <Col xs={24} lg={0} align='center'>
        <Divider />
        <Menu />
      </Col>

    </Row>
  )
}

