import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col, Alert, Spin, Card, Descriptions, Typography, Tag } from 'antd'
import { LoadingOutlined as IconLoading } from '@ant-design/icons'
import idToTokenId from '../../modules/idToTokenId'
import { useContract, useChainState } from '@vechain.energy/use-vechain'
import { ABI } from '../../modules/constants'
import type { Token } from '../List'

const { Text, Paragraph, Title } = Typography


export default function SubscriptionsPending() {
  const params = useParams()
  const [tokenId, setTokenId] = useState<string>('')
  const [tokenExists, setTokenExists] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [token, setToken] = useState<Token>({
    tokenId: '',
    subscriptionId: '',
    image: '',
    owner: '',
    mintedBy: '',
    startedAt: '',
    nextBillingAt: '',
    active: false
  }
  )
  const { ownerOf, tokenURI: getTokenURI, getDataFor } = useContract(process.env.CONTRACT_ADDRESS, ABI)
  const { head } = useChainState()

  const fetchTokenDetails = useCallback(async () => {
    if (!tokenId || !getTokenURI || !getDataFor) { return }

    const tokenURI = await getTokenURI(tokenId)
    const owner = await ownerOf(tokenId)
    const [subscriptionId, startedAt, nextBillingAt] = await getDataFor(['subscription_id', 'started_at', 'next_billing_at'], tokenId)

    const json = JSON.parse(Buffer.from(tokenURI.split(',')[1], 'base64').toString())
    setToken({ ...token, ...json, tokenId, owner, subscriptionId, startedAt, nextBillingAt })
  }, [tokenId, getTokenURI, token])

  useEffect(() => {
    if (params?.subscriptionId === undefined) {
      return setError('Missing subscriptionId')
    }

    idToTokenId(params.subscriptionId)
      .then(tokenId => setTokenId(tokenId))
      .catch(() => { })
  }, [params?.subscriptionId])

  useEffect(() => {
    if (!tokenExists && ownerOf) {
      ownerOf(tokenId)
        .then(owner => setTokenExists(!!owner))
        .catch(() => { })
    }
  }, [tokenId, head, tokenExists, ownerOf])

  useEffect(() => {
    if (!tokenExists) { return }

    if (!token.image) {
      fetchTokenDetails()
    }
  }, [fetchTokenDetails, token, tokenExists])

  return (
    <Row gutter={[0, 32]}>
      {!!error && <Col span={24}><Alert message={error} type="error" /></Col>}
      <Col span={24}>
        <Alert
          type={tokenExists ? 'success' : 'info'}
          showIcon
          icon={tokenExists ? '🎉' : <IconLoading spin />}
          message={tokenExists ? "Congratulations! Your subscription was activated!" : 'Subscription Pending, please wait a moment…'}
        />
      </Col>
      <Col xs={24} md={{ span: 12, offset: 6 }} >
        {tokenExists &&
          <Card
            bordered
            cover={
              token.image === ''
                ?
                <div style={{ textAlign: 'center', margin: 64 }}><Typography.Text type='secondary' style={{ marginTop: 4 }}><Spin tip={<>Our AI is still<br />working on your painting</>} size='small' /></Typography.Text></div>
                :
                <img alt="Artwork" src={token.image} />
            }

          >
            <Card.Meta
              title={<>{token.active ? <Tag color='success'>Active</Tag> : <Tag color='error'>Inactive</Tag>}</>}
              description={
                <Descriptions column={1} size='small' colon={false} labelStyle={{ minWidth: '40%' }}>

                  <Descriptions.Item label="Id">{token.subscriptionId}</Descriptions.Item>
                  {!['', 'undefined'].includes(token.startedAt) && <Descriptions.Item label="Minted at">{(new Date(Number(token.startedAt) * 1000)).toISOString().slice(0, 10)}</Descriptions.Item>}
                  {!['', 'undefined'].includes(token.nextBillingAt) && <Descriptions.Item label="Renewal at">{(new Date(Number(token.nextBillingAt) * 1000)).toISOString().slice(0, 10)}</Descriptions.Item>}
                </Descriptions>
              }
            />
          </Card>}
      </Col>
    </Row>
  )
}