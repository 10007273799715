import React from 'react'
import { Row, Col, Typography, Button, Divider, Alert } from 'antd'
import { useAccount } from '@vechain.energy/use-vechain'

const { Title, Text, Paragraph } = Typography
const isSupported = !!window.vechain
export default function Page() {
  const { connect } = useAccount()
  const handleConnect = async () => connect('share your public wallet address')

  return (
    <Row gutter={[0, 32]}>
      <Col span={24} align='center'>
        <Title level={3}>VeFree • Be Free</Title>
        <img src="https://static.vefree.io/test/30677463288087929316372378280225228915318227956514976738003098492893528944956.png" style={{ maxWidth: 256 }} />
      </Col>
      <Col span={24} align='center'>
        <Paragraph>
          VeFree is a subscription service that pays for your vechain gas fees
        </Paragraph>
        <Paragraph>
          Instead of getting crypto tokens to pay for your blockchain activity, you pay a monthly subscription fee and all your transactions are paid by VeFree.<br />
        </Paragraph>
      </Col>
      <Col span={24}>
        <Divider>How does it work?</Divider>
        <Paragraph>
          <ul>
            <li>Sign In to identify your vechain wallet</li>
            <li>Create a new subscription using your credit card or bank account</li>
            <li>Receive a unique NFT with your subscription with a unique AI generated artwork</li>
            <li>Add <Text code>https://VeFree.io</Text> as default Delegation URL in VeWorld</li>
            <li>Done! All your Gas Fees will be paid be VeFree as long as you hold an active subscription NFT in your wallet</li>
          </ul>
        </Paragraph>
      </Col>

      {!isSupported && <Col span={24}><Alert message='VeWorld could not be detected' description={<><a href="https://veworld.net" target="_blank" rel="noopener noreferrer">Please install the VeWorld Wallet from www.veworld.net</a></>} type="info" /></Col>}

      <Col span={24}>
        <Button size='large' type='primary' block disabled={!isSupported} onClick={handleConnect}>Sign In & Start Now</Button>
      </Col>
    </ Row>
  )
}