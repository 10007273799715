import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Alert, Spin, Button, Tag, Typography, Descriptions, Grid, Popconfirm, Modal, Form, Input, Divider, Card } from 'antd'
import { SendOutlined as IconTransfer, DeleteOutlined as IconDelete } from '@ant-design/icons'
import { useContract, useAccount } from '@vechain.energy/use-vechain'
import { ABI } from '../../modules/constants'

export type Token = {
  tokenId: string
  subscriptionId: string
  image: string
  owner: string
  mintedBy: string
  startedAt: string
  nextBillingAt: string
  active: boolean
}

const { useBreakpoint } = Grid
const { Text, Paragraph, Title } = Typography

export default function SubscriptionsList() {
  const [tokens, setTokens] = useState<Token[]>([])
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [transferring, setTransferring] = useState<boolean>(false)
  const [displayTransfer, setDisplayTransfer] = useState<boolean>(false)
  const [displayDetails, setDisplayDetails] = useState<boolean>(false)
  const [selectedToken, setSelectedToken] = useState<Token | undefined>()
  const [isSponsored, setIsSponsored] = useState<boolean>(false)
  const { account } = useAccount()
  const { balanceOf, tokenOfOwnerByIndex, tokenURI: getTokenURI, ownerOf, burn, transferFrom, getDataFor } = useContract(process.env.CONTRACT_ADDRESS, ABI)
  const screens = useBreakpoint()

  const showTransfer = (token: Token) => () => {
    setDisplayTransfer(true)
    setSelectedToken(token)
  }
  const hideTransfer = () => {
    setError('')
    setSelectedToken(undefined)
    setDisplayTransfer(false)
  }


  const showDetails = (token: Token) => () => {
    setSelectedToken(token)
    setDisplayDetails(true)
  }
  const hideDetails = () => {
    setDisplayDetails(false)
    setSelectedToken(undefined)
  }

  const updateTokenList = useCallback(async () => {
    if (!balanceOf || !tokenOfOwnerByIndex || !getTokenURI || !account) { return }

    setLoading(true)
    setError('')
    try {
      const tokenCount = await balanceOf(account)
      const tokens = []
      for (let index = 0; index < tokenCount; index++) {
        const tokenId = await tokenOfOwnerByIndex(account, index)
        const tokenURI = await getTokenURI(tokenId)
        const owner = await ownerOf(tokenId)
        const [subscriptionId, startedAt, nextBillingAt] = await getDataFor(['subscription_id', 'started_at', 'next_billing_at'], tokenId)

        const json = JSON.parse(Buffer.from(tokenURI.split(',')[1], 'base64').toString())
        tokens.push({ ...json, tokenId, owner, subscriptionId, startedAt, nextBillingAt })
      }

      setTokens(
        tokens
          .sort((a: Token, b: Token) => a.active > b.active ? -1 : 1)
      )
    }
    catch (err) {
      setError(err.message)
    }
    finally {
      setLoading(false)
    }
  }, [balanceOf, tokenOfOwnerByIndex, getTokenURI])


  const handleDelete = useCallback((tokenId: string) => async () => {
    await burn(tokenId)
    await updateTokenList()
  }, [updateTokenList, burn])

  const handleTransfer = useCallback(async ({ address }) => {
    if (selectedToken === undefined) { return }
    setTransferring(true)
    setError('')
    try {
      await transferFrom(account, address, selectedToken.tokenId)
      await updateTokenList()
      hideTransfer()
    }
    catch (err) {
      setError(err.message)
    }
    finally {
      setTransferring(false)
    }
  }, [selectedToken, updateTokenList, transferFrom])


  useEffect(() => {
    setIsSponsored(tokens.find(({ active }) => active === true) !== undefined)
  }, [tokens])

  useEffect(() => {
    updateTokenList()
  }, [updateTokenList])

  return (
    <Row gutter={[0, 32]}>
      {!!error && <Col span={24}><Alert message={error} type="error" /></Col>}
      {isSponsored &&
        <Col span={24} style={{ padding: 16, marginTop: screens.lg ? -72 : 0 }}>
          <Alert
            message="🎉 Congratulations, You're Free!"
            description={<>A subscription is covering your transaction fees. Make sure to set your delegation to <Text code>https://VeFree.io</Text></>}
            type="success"
          />
        </Col>
      }

      <Col span={24}>
        <Spin spinning={loading} tip="Loading your details...">
          {!loading && !tokens.length &&
            <div style={{ textAlign: 'center' }}>
              <Title>Ready to Free Yourself?</Title>
              <Paragraph type='secondary'>
                Our service allows you to pay for transactions with fiat, simplifying the process<br />
                and eliminating the need to convert your fiat to crypto.
              </Paragraph>
              <Paragraph type='secondary'>
                You can cancel your subscription anytime, so there's no long-term commitment.
              </Paragraph>
              {process.env.NETWORK_TYPE === 'test' &&
                <Paragraph type="secondary" italic>
                  You are on the TestNet, use credit card number <code>4111 1111 1111 1111</code> for a free subscription.
                </Paragraph>
              }
              <Paragraph>
                <br />
                <Link to={`https://${process.env.CHARGEBEE_HOSTED_ID}.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=${process.env.CHARGEBEE_SUBSCRIPTION_ID}&subscription[cf_wallet_address]=${account}`}>
                  <Button size='large' type="primary">Subscribe now for €{Number(3.99).toLocaleString()} / month</Button>
                </Link>
              </Paragraph>
            </div>
          }

          {tokens.length > 0 &&
            <Row gutter={[0, 32]}>
              {tokens.map(({ tokenId, subscriptionId, owner, mintedBy, image, active, startedAt, nextBillingAt }, index) => (
                <Col xs={24} md={12} lg={tokens.length < 3 ? 12 : 8} offset={tokens.length === 1 && screens.lg ? 8 : 0} key={tokenId} style={{ padding: 16 }}>
                  <Card
                    hoverable
                    cover={
                      image === ''
                        ?
                        <Typography.Text type='secondary' style={{ marginTop: 4 }}><Spin tip={<>Our AI is still<br />working on your painting</>} size='small' /></Typography.Text>
                        :
                        <img alt="Artwork" src={image} onClick={showDetails(tokens[index])} />
                    }
                    actions={[
                      <Button block key='transfer' style={{ opacity: 0.5 }} size='small' type='text' onClick={showTransfer(tokens[index])} icon={<IconTransfer />}>Transfer</Button>,
                      active ? undefined :
                        <Popconfirm
                          title="Delete Inactive Subscription"
                          description="Once deleted, it can not be restored."
                          okButtonProps={{ danger: true }}
                          okText='Delete'
                          onConfirm={handleDelete(tokenId)}
                        >
                          <Button block size='small' type='text' danger icon={<IconDelete />} style={{ opacity: 0.5 }}>Delete</Button>
                        </Popconfirm>
                    ].filter(element => element !== undefined)
                    }
                  >
                    <Card.Meta
                      title={<>{active ? <Tag color='success'>Active</Tag> : <Tag color='error'>Inactive</Tag>}</>}
                      description={
                        <Descriptions column={1} size='small' colon={false} labelStyle={{ minWidth: '40%' }}>

                          <Descriptions.Item label="Id">{subscriptionId}</Descriptions.Item>
                          {owner !== mintedBy &&
                            <>
                              <Descriptions.Item label="Payee"><Typography.Link ellipsis href={`${process.env.NETWORK_EXPLORER_URL}/accounts/${mintedBy}`} target="_blank" rel="noopener noreferrer">{mintedBy.slice(0, 4)}..{mintedBy.slice(-4)}</Typography.Link></Descriptions.Item>
                              <Descriptions.Item label="User"><Typography.Link ellipsis href={`${process.env.NETWORK_EXPLORER_URL}/accounts/${owner}`} target="_blank" rel="noopener noreferrer">{owner.slice(0, 4)}..{owner.slice(-4)}</Typography.Link></Descriptions.Item>
                            </>
                          }
                          {!['', 'undefined'].includes(startedAt) && <Descriptions.Item label="Minted at">{(new Date(Number(startedAt) * 1000)).toISOString().slice(0, 10)}</Descriptions.Item>}
                          {!['', 'undefined'].includes(nextBillingAt) && <Descriptions.Item label="Renewal at">{(new Date(Number(nextBillingAt) * 1000)).toISOString().slice(0, 10)}</Descriptions.Item>}
                        </Descriptions>
                      }
                    />
                  </Card>
                </Col>
              ))}

              <Col span={24} align='center'>
                <Divider />
                <Paragraph type='secondary'>
                  Do you know someone who could benefit from free transactions on the blockchain?<br />
                  With VeFree, you can create another subscription and gift it to them.<br />
                  <Link style={{ opacity: 0.8 }} to={`https://${process.env.CHARGEBEE_HOSTED_ID}.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=${process.env.CHARGEBEE_SUBSCRIPTION_ID}&subscription[cf_wallet_address]=${account}`}>Create Another Subscription</Link>
                </Paragraph>
              </Col>
            </Row >
          }


          <Modal
            open={displayTransfer}
            onCancel={hideTransfer}
            title='Transfer Subscription'
            footer={null}
            width={640}
          >
            <Paragraph>The recipient will enjoy the full benefits of free subscriptions as long as they are paid for.</Paragraph>
            {selectedToken !== undefined && account === selectedToken.owner &&
              <Paragraph>
                • You will still pay for the subscription until you cancel it.<br />
                • You can cancel the subscription in the Payments section at any time.
              </Paragraph>
            }

            <Divider />

            <Form labelCol={{ span: 4, offset: 2 }} wrapperCol={{ span: 18 }} layout='horizontal' onFinish={handleTransfer}>
              <Form.Item name='address' label='Recipient' rules={[{ required: true, message: 'Please provide the new owner' }]}>
                <Input />
              </Form.Item>

              <Form.Item wrapperCol={{ span: 22, offset: 2, align: 'right' }}>
                <Button type='primary' htmlType='submit' loading={transferring} icon={<IconTransfer />}>Transfer Ownership</Button>
              </Form.Item>

              {!!error && <Col span={24}><Alert message={error} type="error" /></Col>}

            </Form>
          </Modal>

          {selectedToken !== undefined &&
            <Modal
              open={displayDetails}
              onCancel={hideDetails}
              footer={null}
              width={640}
              className='ven-borderless'
            >
              <Card
                bordered
                cover={
                  selectedToken.image === ''
                    ?
                    <Typography.Text type='secondary' style={{ marginTop: 4 }}><Spin tip={<>Our AI is still<br />working on your painting</>} size='small' /></Typography.Text>
                    :
                    <img alt="Artwork" src={selectedToken.image} />
                }
                actions={[
                  <Button block key='transfer' style={{ opacity: 0.5 }} size='small' type='text' onClick={showTransfer(selectedToken)} icon={<IconTransfer />}>Transfer</Button>,
                  selectedToken.active ? undefined :
                    <Popconfirm
                      title="Delete Inactive Subscription"
                      description="Once deleted, it can not be restored."
                      okButtonProps={{ danger: true }}
                      okText='Delete'
                      onConfirm={handleDelete(selectedToken.tokenId)}
                    >
                      <Button block style={{ opacity: 0.5 }} size='small' type='text' danger icon={<IconDelete />} >Delete</Button>
                    </Popconfirm>
                ].filter(element => element !== undefined)
                }
              >
                <Card.Meta
                  title={<>{selectedToken.active ? <Tag color='success'>Active</Tag> : <Tag color='error'>Inactive</Tag>}</>}
                  description={
                    <Descriptions column={1} size='small' colon={false} labelStyle={{ minWidth: '40%' }}>

                      <Descriptions.Item label="Id">{selectedToken.subscriptionId}</Descriptions.Item>
                      {selectedToken.owner !== selectedToken.mintedBy &&
                        <>
                          <Descriptions.Item label="Payee"><Typography.Link ellipsis href={`${process.env.NETWORK_EXPLORER_URL}/accounts/${selectedToken.mintedBy}`} target="_blank" rel="noopener noreferrer">{selectedToken.mintedBy.slice(0, 4)}..{selectedToken.owner.slice(-4)}</Typography.Link></Descriptions.Item>
                          <Descriptions.Item label="User"><Typography.Link ellipsis href={`${process.env.NETWORK_EXPLORER_URL}/accounts/${selectedToken.owner}`} target="_blank" rel="noopener noreferrer">{selectedToken.owner.slice(0, 4)}..{selectedToken.mintedBy.slice(-4)}</Typography.Link></Descriptions.Item>
                        </>
                      }
                      {!['', 'undefined'].includes(selectedToken.startedAt) && <Descriptions.Item label="Minted at">{(new Date(Number(selectedToken.startedAt) * 1000)).toISOString().slice(0, 10)}</Descriptions.Item>}
                      {!['', 'undefined'].includes(selectedToken.nextBillingAt) && <Descriptions.Item label="Renewal at">{(new Date(Number(selectedToken.nextBillingAt) * 1000)).toISOString().slice(0, 10)}</Descriptions.Item>}
                    </Descriptions>
                  }
                />
              </Card>
            </Modal>
          }
        </Spin>
      </Col>
    </Row>
  )
}