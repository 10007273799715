import { theme } from 'antd'

const { darkAlgorithm } = theme

export const dark = {
  algorithm: darkAlgorithm,
  token: {
    colorPrimary: '#1f1f1f',
    colorLink: '#ffffff',
    colorLinkHover: 'rgb(241,84,33,0.5)',
    colorLinkActive: 'rgb(241,84,33,0.5)',
    colorWarning: '#F68A1E',
    colorError: '#ED1D1D',
    borderRadius: 4
  }
}

export const light = {
  token: {
    colorPrimary: 'rgb(241,84,33)',
    colorPrimaryActive: '#cc3b12',
    colorLink: 'rgb(241,84,33)',
    colorLinkHover: 'rgb(241,84,33,0.5)',
    colorLinkActive: 'rgb(241,84,33,0.5)',
    colorWarning: '#F68A1E',
    colorError: '#ED1D1D',
    borderRadius: 4
  }
}