import React from 'react'

export default function Payments() {
  return (
    <iframe
      src={`https://${process.env.CHARGEBEE_HOSTED_ID}.chargebee.com/portal/v2/home?embed=true`}
      width='100%'
      height={700}
      style={{ border: 'none' }}
    />
  )
}