import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { useAccount } from '@vechain.energy/use-vechain'
import React from 'react'

export default function MenuLoggedOut() {
  const { connect } = useAccount()

  const handleConnect = async () => connect('share your public wallet address')

  return (
    <Row gutter={[0, 32]}>
      <Col span={24}>
        <Link to='/'>Home</Link>
      </Col>
      <Col span={24}>
        <Link to='/help'>Help & How To Setup</Link>
      </Col>
      <Col span={24}>
        <Link to='/legals'>Legal Information</Link>
      </Col>
      <Col span={24}>
        <Link to='/' onClick={handleConnect}>Sign In</Link>
      </Col>
      <Col span={24} />
    </Row>
  )
}