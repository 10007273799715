import React from 'react'
import { Row, Col, Typography, Image } from 'antd'
const { Title, Paragraph, Text, Link: LinkExt } = Typography
export default function Help() {

  return (
    <Row gutter={[0, 32]}>
      <Col span={24}>
        <Title level={3}>What is VeFree?</Title>
        <Paragraph>
          VeFree is a blockchain-powered payment platform that lets you transact with ease using fiat currency. Say goodbye to the complexities of crypto and embrace a simpler, more accessible way using the blockchain.
        </Paragraph>
        <Paragraph>
          With VeFree, you can subscribe to our service using your credit card or bank account, giving you the freedom to pay for your transactions in the way that suits you best. Our platform is designed to be easy to use, so you can focus on what matters most.
        </Paragraph>
        <Paragraph>
          We believe in the power of blockchain technology, but we also know that not everyone has the time or inclination to learn about it in depth. That's why we've made it our mission to simplify the process for you, so you can enjoy the benefits without the hassle.
        </Paragraph>
        <Paragraph>
          Plus, when you sign up with VeFree, you'll receive a unique AI artwork as an NFT - our way of saying thank you for choosing us.
        </Paragraph>
      </Col>

      <Col span={24}>
        <Title level={3}>How to use it?</Title>
        <Paragraph>
          To use VeFree, you'll need to use VeWorld, the official wallet of vechain, which is available for download at <LinkExt href='https://www.veworld.net/' target='_blank' rel='noopener noreferrer'>VeWorld.net</LinkExt>.
        </Paragraph>
        <Paragraph>
          Once you've downloaded VeWorld, navigate to your settings and add <Text code>https://VeFree.io</Text> as your delegation URL.
        </Paragraph>
        <Paragraph>
          This will enable VeFree to pay for your transactions.
        </Paragraph>
      </Col>

      <Col span={24}>

        <Title level={3}>Configuration Instructions</Title>
        <Row gutter={[16, 64]}>
          <Col xs={8} lg={12} >
            Open Settings for Transactions:
          </Col>
          <Col xs={8} lg={12} >
            Select "Default Delegation" with "URL" and enter <Text code>https://VeFree.io</Text>:
          </Col>

          <Col xs={8} lg={12} >
            <Image src={require('./Config-Settings.webp')} />
          </Col>
          <Col xs={8} lg={12} >
            <Image src={require('./Config-Delegation.webp')} />
          </Col>

          <Col xs={24}>
            <Image src={require('./VeFree-Setup.gif')} />
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Title level={3}>Unique Artwork</Title>
        <Paragraph>
          Every VeFree subscription purchase is represented by a unique NFT token stored on the vechain blockchain.
        </Paragraph>
        <Paragraph>
          To make it more special, we generate a one-of-a-kind artwork using artificial intelligence during the purchase process.
        </Paragraph>
        <Paragraph>
          The artwork is attached to the NFT and will remain with you forever, making your VeFree subscription not only functional, but also visually pleasing.
        </Paragraph>
      </Col>

      <Col span={24}>
        <Title level={3}>Transferring Subscription Ownership</Title>
        <Paragraph>
          With VeFree, you have the flexibility to transfer your subscription to different wallets. This is useful if you want to pay for someone else's subscription or if you've moved to a new wallet.
        </Paragraph>
        <Paragraph>
          The wallet that owns an active subscription can benefit from free transactions, so it's important to keep it up-to-date. However, if access to a subscription is lost but still paid for, it can be easily deactivated in the payments section. Simply cancel the payment, and the subscription will be automatically deactivated. This ensures that you're only paying for what you're using, giving you greater control and peace of mind.
        </Paragraph>
      </Col>

      <Col span={24}>
        <Title level={3}>Types of Transactions</Title>
        <Paragraph>
          All regular user transactions are paid for, with the exception of contract deployments for developers due to the high gas costs.
        </Paragraph>
        <Paragraph>
          To prevent abuse, the number of transactions is limited to a fair use model. If a subscription exceeds the average usage, we may not renew it.
        </Paragraph>
        <Paragraph italic>
          As VeFree is a new service, we're still refining our fair use policy and the exact number of transactions allowed. Rest assured, we're committed to providing a transparent and equitable experience for all our users, and will continue to evolve our policy as we gain more experience.
        </Paragraph>
      </Col>
    </Row>
  )
}