import React from 'react'
import { Row, Col, Typography, Grid } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { useAccount } from '@vechain.energy/use-vechain'
import Logo from '../../common/Logo'

const { Title } = Typography
const { useBreakpoint } = Grid

export default function MenuLoggedIn() {
  const { disconnect } = useAccount()
  const navigateTo = useNavigate()
  const screens = useBreakpoint()

  const handleDisconnect = async () => {
    await disconnect()
    navigateTo('/')
  }

  return (
    <Row gutter={[0, 32]}>
      {screens.lg &&
        <Col span={24}>
          <Title level={2}>VeFree</Title>
        </Col>
      }
      <Col span={24}>
        <Link to='/'>My Subscription</Link>
      </Col>
      <Col span={24}>
        <Link to='/payments'>Manage Payments</Link>
      </Col>
      <Col span={24}>
        <Link to='/help'>Help & How To Setup</Link>
      </Col>
      <Col span={24}>
        <Link to='/legals'>Legal Information</Link>
      </Col>
      <Col span={24}>
        <Link to='/' onClick={handleDisconnect}>Sign Out</Link><br />
      </Col>
      <Col span={24} />
    </Row >
  )
}