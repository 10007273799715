import React from 'react'
import { Typography } from 'antd'
const { Paragraph, Text, Link: LinkExt, Title } = Typography

export default function Legals() {
  return (
    <>
      <Title level={3}>Imprint</Title>
      <Paragraph>
        VeFree is a product of <LinkExt href='https://vechain.energy' target='_blank' rel='noreferrer'>vechain.energy</LinkExt>. If you have any questions please visit our <LinkExt href='https://discord.com/invite/dhVCVNbHRT' target='_blank' rel='noreferrer'>Discord</LinkExt> or write an <LinkExt href='mailto:contact@vechain.energy'>email</LinkExt>.
      </Paragraph>

      <Paragraph>
        for legal issues please use the address below:
      </Paragraph>

      <Paragraph>
        <Text strong>vechain.energy</Text><br />
        Mainzer Allee 23<br />
        65323 Taunusstein<br />
        🇩🇪 Germany<br />
      </Paragraph>

      <Title level={3}>Privacy Policy</Title>
      <Paragraph>
        We do not collect any personal information on our website. We use Chargebee, a third-party payment management service, to handle all payments. Chargebee collects personal information such as your name, address, email address, and credit card information to process payments. All information at Chargebee is automatically deleted immediately after cancellation or automatically after a subscription ends.
      </Paragraph>
      <Paragraph>
        If you have any questions or concerns about our privacy policy, please contact us at <LinkExt href='mailto:contact@vechain.energy'>email</LinkExt>.
      </Paragraph>
    </>
  )
}
